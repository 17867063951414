<template>
  <div class="RecommentGoodItem"  @click="goDetail">
    <div
      class="name"
      :style="{ color: data.nameColor, fontSize: data.fontSize }"
    >
      {{$cl('name',data,$i18n.locale)}}
    </div>
    <div class="decripe">
      <div class="txt">
         {{$cl('brief',data,$i18n.locale)}}
      </div>
    </div>

    <div class="knowBtn">
      <span class="txt"> {{$t('Home.Button.LearnMore')}} > </span>
    </div>

    <div class="img">
      <div class="imgBox">
        <img class="img1" v-lazy="data.images[0] || data.big" alt="" />
        <img class="img2" v-lazy="data.images[1] || data.big" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RecommentGoodItem",
  data() {
    return {};
  },
  created() {},
  methods: {
    //去商品详情页
    goDetail() {
      let product_id = this.data.product_id;
      this.$router.push({
        path: "/product/index",
        query: {
          product_id: product_id,
        },
      });
    },

    // 打开商品详情弹框
    openDetil() {
      this.$store.commit("updateProductTk", {
        val: this.data.product_id,
        isOpen: true,
      });
    },
  },
  props: {
    data: {
      type: Object,
    },
  },
};
</script>
<style lang='scss' scoped>
.RecommentGoodItem {
  background-color: white;
  max-width: 2560px;
  margin: 0 auto 0 auto;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 400px;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    .imgBox {
      transform: translateX(-50%);
      .img1 {
        opacity: 0;
      }

      .img2 {
        opacity: 1;
      }
    }
  }

}

.img {
  cursor: pointer;
  width: 80%;
  margin: 15px auto 0 auto;
  min-height: 150px;
  overflow: hidden;
  padding-bottom: 35px;
  .imgBox {
    transition: transform 0.3s;
    display: flex;
    width: calc(100% * 2);

    img {
      flex: none;
      width: 50%;
      opacity: 1;
      transition: opacity 0.3s;
    }

    .img2 {
      opacity: 0;
    }
  }

  
}

.name {
  font-size: 26px;
  text-align: center;
  padding-top: 30px;
  font-weight: 600;
  color: var(--text-color-default);
}

.decripe {
  color: var(--text-color-minor);
  font-size: var(--font-size-sm);
  display: flex;
  justify-content: center;
  letter-spacing: 2px;
  margin: 5px;
}

.knowBtn {
  margin-top: 10px;
  text-align: center;
  color: var(--success-color);
  font-size: var(--font-size-sm);
  cursor: pointer;
  .txt {
    &:hover {
      border-bottom: 1px solid var(--success-color);
    }
  }
}
</style>