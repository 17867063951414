<template>
  <div class="home">
    <!-- <div class="ad_list">
      <div class="ad_item" v-for="item in huandengData" :key="item.img">
        <recomment-good-item :data="item"></recomment-good-item>
      </div>
    </div> -->

    <new-empty v-if="isShowEmpty" :image="emptyState.img" :decripe="emptyState.decripe"></new-empty>
    <def-loading :isshow="isShowLoading"></def-loading>

    <div class="gongGe container">
      <div class="item" v-for="item in goods_3_Data" :key="item.goods_id">
        <recomment-good-item :data="item"></recomment-good-item>
      </div>
    </div>


  </div>
</template>
<script>
import { RecommentGoodItem } from "./child";
import Page from "@/model/Page.js";
import NewEmpty from "@/components/common/empty/NewEmpty";
import DefLoading from "@/components/common/loading/def/DefLoading";
export default {
  name: "Home",
  data() {
    return {
      // 首页幻灯数据用于商品展示
      huandengData: [],

      // goods3产品数据
      goods_3_Data: [],

      // 是否显示空状态
      isShowEmpty: false,

      // 是否显示加载
      isShowLoading:false,

      // 空状态显示的图片类型和描述
      emptyState:{
        img:'',
        decripe:''
      }
    };
  },
  components: {
    RecommentGoodItem,
    NewEmpty,
    DefLoading
  },
  created() {
    this.getHomeData();
  },

  methods: {
    // 获取首页数据
    getHomeData() {
      this.isShowLoading = true
      Page.Index({
        data: {},
        succ: (res, all) => {
          this.isShowLoading = false
          let allData = this.disposeData(res);
          this.huandengData = allData.huandeng.data;
          this.goods_3_Data = allData.goods_3.data.goods;
        },
        fail: (res, all) => {
          this.emptyState.img = 'defErr'
          this.emptyState.decripe = '加载失败'
          this.isShowEmpty = true;
          this.isShowLoading = false

          this.$message('error',all.msg)
        },
      });
    },

    // 处理首页数据
    disposeData(obj) {
      let res = {};
      for (let key in obj) {
        res[obj[key].type] = obj[key];
      }

      return res;
    },
  },
};
</script>
<style lang='scss' scoped>
.home {
  background-color: var(--bg-color-hint);
  // padding-top: 30px;
  min-height: 400px;
  position: relative;
}

.ad_list {
  .ad_item:not(:last-child) {
    margin-bottom: 12px;
  }
}

.gongGe {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
  .item {
    width: 33.33%;
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>